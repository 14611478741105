/* @import url("./assets/fonts/vazir/font-face.css"); */

::-moz-selection {
  background: #f521a9;
  color: #fafafa;
  text-shadow: none;
}
::selection {
  background: #f521a9;
  color: #fafafa;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 16px;
}
body {
  font-family: Vazirmatn;
  transition: all 0.5s ease;
  direction: rtl;
}

p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
